import axios from 'axios';
import store from '@/store';

const restApi = axios.create({
  baseURL: process.env.VUE_APP_REST_API_URL
});

restApi.interceptors.request.use(config => {
  if (store.getters['oidcStore/oidcAccessToken']) {
    const token = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`;

    config.headers.common['Authorization'] = token;
  }

  return config;
}, error => Promise.reject(error));

export default restApi;
