// context org
export const muSetContextOrgId = 'muSetContextOrgId';

// orgs
export const muSetOrgs = 'muSetOrgs';
export const muSetOrgsLoading = 'muSetOrgsLoading';
export const muSetOrgsError = 'muSetOrgsError';

// person
export const muPerson = 'muPerson';
export const muPersonLoading = 'muPersonLoading';
export const muPersonError = 'muPersonError';
export const muCanReadPerson ='muCanReadPerson';

// memberships
export const muSetMemberships = 'muSetMemberships';
export const muMembershipsLoading = 'muMembershipsLoading';
export const muMembershipsError = 'muMembershipsError';

// sports
export const muSetSports = 'muSetSports';
export const muSetSportsLoading = 'muSetSportsLoading';
export const muSetSportError = 'muSetSportError';

// policecertificates
export const muSetPolicecertificate = 'muSetPolicecertificate';
export const muSetPolicecertificates = 'muSetPolicecertificates';
export const muSetPolicecertificateById = 'muSetPolicecertificateById';
export const muSetPolicecertificateByStatus = 'muSetPolicecertificateByStatus';
export const muSetPoliceStatistics = 'muSetPoliceStatistics';
export const muSetPolicecertificateLoading = 'muSetPolicecertificateLoading';
export const muSetPolicecertificateError = 'muSetPolicecertificateError';
export const muSavePolicecertificate = "muSavePolicecertificate";

// coaches
export const muSetCoachLoading = 'muSetCoachLoading';
export const muSetCoachError = 'muSetCoachError';
export const muSetCoachItemsChartData = 'muSetCoachItemsChartData';
export const muSetCoachItems = 'muSetCoachItems';

// person avatar
export const muPersonAvatar = 'muPersonAvatar';
export const muPersonAvatarLoading = 'muPersonAvatarLoading';
export const muPersonAvatarError = 'muPersonAvatarError';
