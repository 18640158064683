import {
    muSetCoachLoading,
    muSetCoachError,
    muSetCoachItemsChartData,
    muSetCoachItems
} from './mutation-types';

import api from '@/utils/coach-api';

const state = {
    errorMessage: null,
    loading: false,
    coachItemsChartData: [],
    coaches: []
};

const getters = {
    errorMessage: () => state.errorMessage,
    coachItemsChartData: () => state.coachItemsChartData,
    loading: () => state.loading,
    coaches: ()=> state.coaches.reduce((items, value) => {
      // Group initialization
      if (!items[value.personId]) {
        items[value.personId] = [];
      }
      // Grouping
      items[value.personId].push(value);
      return items;
    }, {})
};

const actions = {
  async fetchCoachItemsChartData({ commit }, { orgId, orgTypeId }) {
    commit(muSetCoachLoading);

    try {
      if (orgId <= 0) {
        commit(muSetCoachItemsChartData, []);
        return;
      }
      if (orgTypeId <= 0) {
          commit(muSetCoachItemsChartData, []);
          return;
      }
      const data = { orgId: orgId, orgTypeId: orgTypeId };
      const response = await api.post('./api/v1/Statistics/GetChartData', data);
      commit(muSetCoachItemsChartData, response.data);
    } catch (e) {
      commit(muSetCoachError, { errorMessage: e.message });
    }
  },

  async fetchCoachItems({ commit }, orgId ) {
    commit(muSetCoachLoading);

    try {
      if (orgId <= 0) {
        commit(muSetCoachItems, []);
        return;
      }
      const data = {"orgId": orgId};
      const response = await api.post('./api/v1/Coach/GetCoachesWithPoliceCertificate', data);

      commit(muSetCoachItems, response.data);
    } catch (e) {
      commit(muSetCoachError, { errorMessage: e.message });
    }
  }
};

const mutations = {
    [muSetCoachLoading]() {
      state.loading = true;
      state.errorMessage = '';
    },
    [muSetCoachItemsChartData](_,items) {
        state.loading = false;
        state.coachItemsChartData = items;
    },
    [muSetCoachItems](_,items) {
      state.loading = false;
      state.coaches = items;
    },
    [muSetCoachError](_, { errorMessage }) {
      state.loading = false;
      state.errorMessage = errorMessage;
    }
};

export const coaches = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};