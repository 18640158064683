import api from '@/utils/api';

import {
  muPerson,
  muPersonLoading,
  muPersonError,
  muCanReadPerson
} from './mutation-types';

const state = {
  loading: false,
  person: {
    personId: 0,
    firstName: '',
    lastName: '',
    birthDate: '',
    genderId: 0,
    address: '',
    postCode: '',
    city: '',
    email: '',
    mobilePhone: ''
  },
  errorMessage: null,
  canReadPerson: false
};

const getters = {
  personLoading: ()=> state.loading,
  person: () => state.person,
  personErrorMessage: () => state.errorMessage,
  canReadPerson: () => state.canReadPerson
};

const actions = {
  async fetchPerson({ commit }, personId) {
    commit(muPersonLoading);

    if (personId <= 0) {
      return;
    }
    try {
      const data = { id: Number(personId) };
      const response = await api.post('./api/v1/Person', data);

      commit(muPerson, response.data);
    } catch (e) {
      commit(muPersonError, { errorMessage: e.message });
    }
  },
  async fetchCanReadPerson({ commit }, item) {
    commit(muPersonLoading);

    if (item.personId <= 0 && item.orgId) {
      return;
    }
    try {
      const data = { orgContextId: Number(item.orgId), orgTypeId: Number(item.orgTypeId),personId: Number(item.personId) };
      const response = await api.post('./api/v1/Person/CanReadPerson', data);

      commit(muCanReadPerson, response.data);
    } catch (e) {
      commit(muPersonError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muPersonLoading]() {
    state.loading = true;
    state.errorMessage = '';
  },

  [muPerson](_, item) {
    state.loading = false;
    state.person = item;
  },

  [muCanReadPerson](_, item) {
    state.loading = false;
    state.canReadPerson = item;
  },

  [muPersonError](_, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const persons = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
