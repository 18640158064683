import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from './i18n';
import '@/assets/css/main.scss';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    t: (key, ...params) => {
      i18n.t(key, params);
    }
  },
  theme: {
    themes: {
      options: {
        customProperties: true
      },
      light: {
        primary: {
          lighten8: "E4E4E9",
          lighten7: "CBCBD5",
          lighten6: "B1B2C1",
          lighten5: "9899AD",
          lighten4: "7E8099",
          lighten3: "656785",
          lighten2: "4C4E71",
          lighten1: "32355D",
          base: "000335"
        },
        'primary-variant': {
          lighten8: "E4E7F0",
          lighten7: "BCC3DA",
          lighten6: "919DC0",
          lighten5: "6778A7",
          lighten4: "485C96",
          lighten3: "254287",
          lighten2: "153273",
          lighten1: "0C2967",
          //matches primary button
          base: "001A51"
        },
        secondary: {
          lighten8: "E2F6FE",
          lighten7: "B5E6FB",
          lighten6: "85D6F9",
          lighten5: "57C6F6",
          lighten4: "38BAF5",
          lighten3: "24ADF3",
          lighten2: "209FE4",
          lighten1: "1B8CD0",
          //matches secondary button
          base: "197BBC",
          darken1: "#135B9A"
        },
        //previously "navbarBlue"
        'secondary-variant': "003E7E",
        accent: {
          base: "FD3932",
          darken1: "EE2D32",
          darken2: "DC212C",
          darken3: "CF1724",
          darken4: "C10017"
        },
        //identical to 'secondary', specified as it has semantic meaning in Vuetify
        info: "197BBC",
        warning: "FAA634",
        success: "007D2E",
        error: "E32D22",
        //default background (light gray)
        'background': "F4F2F2",
        //variant background (light blue)
        'background-variant': "F0F4FC",
        //background for inactive elements
        'background-inactive': "F4F2F2",
        //background for card-like elements
        'background-card': "FFFFFF",
        //text/border etc for inactive elements
        'inactive': "A19FAF",
        //inverted color for text etc on dark backgrounds
        'inverted': "FFFFFF"
      }
    }
  }
});
