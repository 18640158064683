import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import Role from '@/plugins/role';
import authorize from '@/plugins/authorize';
import store from './store';
import '@/plugins/appinsights';
import moment from 'moment';

Vue.config.productionTip = false;

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('DD.MM.YYYY');
  }

  return null;
});

new Vue({
  vuetify,
  i18n,
  router,
  Role,
  authorize,
  store,
  render: h => h(App)
}).$mount('#app');