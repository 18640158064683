import {
  muSetSports,
  muSetSportsLoading,
  muSetSportError
} from './mutation-types';

import api from '@/utils/api';

const state = {
  sports: [],
  loading: false,
  errorMessage: ''
};

const getters = {
  sports: () => state.sports,
  getSportNames: sportIds => {
    return state.sports
      .filter(x => sportIds.indexOf(x.id) !== -1)
      .map(x => x.sportName)
      .join(', ');
  }
};

const actions = {

  async fetchSports({ commit }, orgId) {
    commit(muSetSportsLoading);
    try {
      const data = {id: Number(orgId)};
      const response = await api.post('./api/v1/Org/GetSportsForOrg', data);
      commit(muSetSports, response.data);
    } catch (e) {
      commit(muSetSportError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muSetSportsLoading]() {
    state.loading = true;
    state.errorMessage = '';
  },

  [muSetSports](_, items) {
    state.loading = false;
    state.sports = items;
  },

  [muSetSportError](_, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const sports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};