import Vue from 'vue';
import store from '@/store';
import router from '@/plugins/router';

export default Vue.directive('authorize', function(el, binding){
   // Hide the element if the user is not authorized
  let route;
  router.options.routes.forEach(x => {
   if(x.path.toLowerCase() === binding.value.path.toLowerCase()){
      route = x;
     }
  });
  const { authorize } = route.meta;
  const isAuthenticated = store.getters['oidcStore/oidcIsAuthenticated'];
  if (authorize) {
      if (!isAuthenticated) {
         el.hidden = true;
      }
      const currentUser = store.getters['oidcStore/oidcUser'];
      const functionTypes =  currentUser.functiontypeids.split(',').map(x => Number(x));
      const functionTypeIds = [...new Set(functionTypes)];
      const authorizeIds = [...new Set(authorize[0])];
      const intersection = authorizeIds.filter(element => functionTypeIds.includes(element));
      // check if route is restricted by role
      if (authorize.length && intersection.length <= 0) {
          // role not authorised so hide element
          el.hidden = true;
      }else{
         el.hidden = false;
      }
   }else{
      el.hidden = true;
   }
});