import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import OidcCallback from '@/views/auth/OidcCallback';
import OidcCallbackError from '@/views/auth/OidcCallbackError';
import store from '@/store';
import { Role } from './role';
import { Orgtypes } from '@/mixins/orgtypes';
import { authorizeMixin } from '@/mixins/authorizeMixin';

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'Home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue'),
      meta: { authorize: []}
    },
    {
      path: '/Search',
      name: 'Search',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/certificate/SearchPage.vue'),
      meta: { authorize: []}
    },
    {
      path: '/NoAccess',
      name: 'NoAccess',
      // route level code-splitting
      // this generates a separate chunk (coachCertificatePage.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "coachCertificatePage" */ '../views/common/components/NoAccess.vue'),
      meta: { authorize: []}
    },
    {
      path: '/policecertificates/:id',
      name: 'PoliceCertificates',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/certificate/PoliceCertificates.vue'),
      meta: { authorize: []}
    },
    {
      path: '/coaches',
      name: 'Coaches',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/certificate/CoachesPage.vue'),
      meta: { authorize: []}
    },
    {
      path: '/Edit/:id',
      name: 'Edit',
      // route level code-splitting
      // this generates a separate chunk (confirmCoachCertificatePage.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "confirmCoachCertificatePage" */ '../views/certificate/EditPage.vue'),
      meta: { authorize: [Role.PoliceCertificateAdmin], orgtypes: Orgtypes.Club}
    },
    {
      // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback
    },
    {
       // Needs to match redirect_uri in you oidcSettings
      path: '/oidc-callback-error',
      name: 'oidcCallbackError',
      component: OidcCallbackError,
      meta: {
        isPublic: true
      }
    }
  ];

  const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function(to) {
      if (to.hash) {
        return {selector: to.hash};
      } else {
        return {x: 0, y: 0};
      }
    },
    routes
  });

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.beforeEach((to, _from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const orgtypes = to.meta.orgtypes;
  let orgTypeAllowed = true;
   if (orgtypes !== undefined && orgtypes.length > 0) {
    orgTypeAllowed = authorizeMixin.methods.isOrgTypesAllowed(orgtypes);
   }
  const isAuthenticated = store.getters['oidcStore/oidcIsAuthenticated'];
  if (authorize) {
      if (!isAuthenticated) {
          next();
      }
      const currentUser = store.getters['oidcStore/oidcUser'];
      const functionTypes =  currentUser.functiontypeids.split(',').map(x => Number(x));
      const functionTypeIds = [...new Set(functionTypes)];
      const authorizeIds = [...new Set(authorize[0])];

      const intersection = authorizeIds.filter(element => functionTypeIds.includes(element));
      // check if route is restricted by role
      if (authorize.length && intersection.length <= 0 || !orgTypeAllowed) {
          // role not authorised so redirect to NoAccess page
          next({ path: '/NoAccess' });
      }
  }
  next();
});


export default router;