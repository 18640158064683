<template>
  <v-app-bar dark height="80" color="primary" app flat>
    <v-container class="wide-container mx-auto">
      <v-row class="mx-n5">
        <v-col cols="5">
          <v-toolbar-title>
            <router-link to="/">
              <v-img class="d-flex my-1" src="@/assets/nif-logo-white.svg" position="left"
                contain id="nif-logo-white" max-height="60" height="60"
                alt="Norges idrettsforbund"
              ></v-img>
            </router-link>
          </v-toolbar-title>
        </v-col>
        <v-col cols="7">
          <div class="float-right pb-3 pt-4 mr-n2">
            <v-menu v-if="oidcIsAuthenticated" :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ value, on, attrs }">
                <v-btn v-bind="attrs" v-on="on" dark text rounded class="font-weight-regular text-none ml-2 mr-5 pl-2 pr-1">
                  <span v-if="!mobile" class="text-right" style="white-space: normal;">
                    {{ fullName }}
                    <div class="caption text-truncate" style="max-width: 150px;">
                      {{ getOrgName(contextOrgId) }}
                    </div>
                  </span>
                  <v-avatar v-if="personAvatar" size="34" class="account-icon ml-2">
                    <img :src="personAvatar" alt="Profil" />
                  </v-avatar>
                  <v-icon right class="account-icon" v-else>mdi-account</v-icon>
                  <v-icon right x-small class="ml-1">{{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>
               <template v-slot:default="data">
                <v-dialog
                  v-if="mobile" width="100%" class="menu-dialog"
                  fullscreen :value="data.value" persistent>
                  <UserMenu @click="data.value=false"></UserMenu>
                </v-dialog>
                <UserMenu :width="350" @click="data.value=false" v-else></UserMenu>
              </template>
            </v-menu>

            <v-menu offset-y transition="slide-y-transition">
              <template v-slot:activator="{ value, on, attrs }">
                <v-btn v-bind="attrs" v-on="on" dark text rounded outlined class="font-weight-regular text-none pl-1 pr-1" :min-width="mobile ? 35 : 110">
                  <span v-if="!mobile" class="pl-3 pr-2">{{ value ? $t('commonStrings.close') : $t('commonStrings.menu') }}</span>
                  <v-icon :class="mobile ? '' : 'pr-2'">{{ value ? 'mdi-close' : 'mdi-menu' }}</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="data">
                <v-dialog
                  v-if="mobile" width="100%" class="menu-dialog" content-class="primary"
                  fullscreen :value="data.value" persistent>
                  <AppMenu @click="data.value=false"></AppMenu>
                </v-dialog>
                <AppMenu :width="350" @click="data.value=false" v-else></AppMenu>
              </template>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserMenu from '@/views/common/components/UserMenu.vue';
import AppMenu from '@/views/common/components/AppMenu.vue';
import { Role } from '@/plugins/role';

export default {
  name: 'PageHeader',
  computed: {
    ...mapGetters('oidcStore', [
      'oidcIsAuthenticated'
    ]),
    ...mapGetters('claims', [
      'personId',
      'givenName',
      'familyName',
      'contextOrgId',
      'orgContexts',
      'oidcUser'
    ]),
    ...mapGetters('orgs', [
      'orgs'
    ]),
    ...mapGetters('personAvatar', [
      'personAvatar'
    ]),
    contextOrgIdKey() {
      return `contextOrgId_${this.personId}`;
    },
    fullName() {
      if (!this.oidcIsAuthenticated) {
        return '';
      }
      return `${this.givenName} ${this.familyName}`;
    },
    filteredOrgContexts() {
      const allowedFuntionTypes = Role.PoliceCertificateAdmin;
      return this.orgContexts
        .filter(orgContext => orgContext
          .userContexts
          .some(userContext => allowedFuntionTypes
            .indexOf(userContext.functionTypeId) > -1)
        );
    },
    mobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  },
  watch: {
    filteredOrgContexts() {
      if (this.contextOrgId) {
        return;
      }
      const firstOrgContext = this.filteredOrgContexts.find(() => true);
      this.setContextOrgId(firstOrgContext.orgId);
    },
    oidcIsAuthenticated() {
      if (this.oidcIsAuthenticated) {
        const orgIds = this.filteredOrgContexts.map(x => x.orgId);
        this.fetchOrgs(orgIds);
        this.fetchPersonAvatar();
      }
    }
  },
  methods: {
    ...mapActions('orgs', [
      'fetchOrgs'
    ]),
    ...mapActions('claims', [
      'setContextOrgId'
    ]),
    ...mapActions('personAvatar',[
      'fetchPersonAvatar'
    ]),
    getOrgName(orgId) {
      const org = this.orgs.find(x => x.orgId === orgId);
      return org?.describingName;
    }
  },
  components: {
    UserMenu,
    AppMenu
  }
};
</script>

<style>
.v-app-bar > .v-toolbar__content .theme--dark.v-btn.v-btn--outlined.v-btn--text {
  border-color: #fff;
}
</style>

<style scoped>
.account-icon {
  border-radius: 20px;
  padding: 15px;
  border: 2px solid #FD3932;
}
</style>