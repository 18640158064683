const OrgType = {
    Gren: 14,
    Allidrett: 36,
    Bedriftsidrettsgruppe: 60,
    KL: 5,
    BL: 15,
    SF: 2,
    IK: 3,
    BK: 16,
    Region: 48,
    SK: 4,
    IR: 8,
    Lag: 7
  };

  Object.freeze(OrgType);

  export default OrgType;