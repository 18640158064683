import {
    muSetMemberships,
    muMembershipsLoading,
    muMembershipsError
  } from './mutation-types';

  import api from '@/utils/api';

  const state = {
    memberships: [],
    loading: false,
    errorMessage: ''
  };

  const getters = {
    memberships: () => state.memberships,
    loading: () => state.loading,
    errorMessage: () => state.errorMessage
  };

  const actions = {
    async fetchMemberships({ commit }, item) {
      commit(muMembershipsLoading);

      try {
        if (item.orgId <= 0) {
          commit(muSetMemberships, []);
          return;
        }
        let sportIds = [];
        if (Array.isArray(item.sportIds)) {
          sportIds = item.sportIds;
        } else if (item.sportIds == null) {
          sportIds = [];
        } else {
          sportIds = [item.sportIds];
        }
        const data = {
          contextOrgId: item.orgId,
          searchString: '',
          sportIds: sportIds
        };
        const response = await api.post('./api/v1/Membership/Search', data);
        commit(muSetMemberships, response.data);
      } catch (e) {
        commit(muMembershipsError, { errorMessage: e.message });
      }
    }
  };

  const mutations = {
    [muMembershipsLoading]() {
      state.loading = true;
      state.errorMessage = '';
    },

    [muSetMemberships](_, items) {
      state.loading = false;
      state.memberships = items;
    },

    [muMembershipsError](_, { errorMessage }) {
      state.loading = false;
      state.errorMessage = errorMessage;
    }
  };

  export const memberships = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };