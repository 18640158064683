import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_COACH_API_URL
});

instance.interceptors.request.use(config => {
  if (store.getters['oidcStore/oidcAccessToken']) {
    const token = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`;
    const contextOrgId = store.getters['claims/contextOrgId'];

    config.headers.common['Authorization'] = token;
    config.headers.common['nif-org-id'] = contextOrgId;
  }

  return config;
}, error => Promise.reject(error));

const coachapi = {
  get(resource) {
    return instance.get(resource);
  },
  post(resource, data) {
    return instance.post(resource, data);
  }
};

export default coachapi;