import {
  muSetPolicecertificate,
  muSetPolicecertificates,
  muSetPolicecertificateById,
  muSetPolicecertificateByStatus,
  muSetPoliceStatistics,
  muSavePolicecertificate,
  muSetPolicecertificateLoading,
  muSetPolicecertificateError
} from './mutation-types';

import api from '@/utils/api';

const state = {
  policecertificates: [],
  statistics: {},
  policecertificate: {},
  policecertificatesbystatus: {},
  loading: false,
  errorMessage: ''
};

const getters = {
  policecertificates: () => state.policecertificates,
  policecertificatesbystatus: () => state.policecertificatesbystatus,
  statistics: () => state.statistics,
  policecertificate: () => state.policecertificate,
  statuses: () => state.statusEnum,
  loading: () => state.loading,
  errorMessage: () => state.errorMessage
};

const actions = {
  async fetchPoliceCertificates({ commit }, orgId) {
    commit(muSetPolicecertificateLoading);

    try {
      if (orgId <= 0) {
        commit(muSetPolicecertificates, []);
        return;
      }

      const data = {
        "orgId": orgId
      };
      const response = await api.post('./api/v1/Org/PoliceCertificates', data);
      commit(muSetPolicecertificates, response.data);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },
  async fetchPoliceCertificatesByStatusId({ commit }, { statusId, orgId }) {
    commit(muSetPolicecertificateLoading);
    try {
      if (orgId <= 0) {
        commit(muSetPolicecertificateByStatus, []);
        return;
      }

      const data = {
        "orgId": orgId,
        "status": Number(statusId)
      };
      const response = await api.post('./api/v1/OrgContract/PoliceCertificates', data);
      commit(muSetPolicecertificateByStatus, response.data);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },

  async fetchPoliceStatistics({ commit }, orgId) {
    commit(muSetPolicecertificateLoading);

    try {
      if (orgId <= 0) {
        commit(muSetPoliceStatistics, {});
        return;
      }

      const data = {
        ContextOrgId: orgId
      };
      const response = await api.post('./api/v1/PoliceCertificate/Statistics', data);
      const item = response.data;
      commit(muSetPoliceStatistics, item);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },

  async fetchPoliceCertificateById({ commit }, item) {
    commit(muSetPolicecertificateLoading);

    try {
      if (item <= 0) {
        commit(muSetPolicecertificate, {});
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 3000));
      const response = await api.get(`./api/v1/PoliceCertificate/${item}`);
      commit(muSetPolicecertificateById, response.data);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },

  async fetchPoliceCertificate({ commit }, item) {
    commit(muSetPolicecertificateLoading);

    try {
      if (item.personId <= 0 && item.orgId <= 0) {
        commit(muSetPolicecertificate, {});
        return;
      }
      const data = {
        "personIds": [
          Number(item.personId)
        ],
        "orgId": Number(item.orgId)
      };
      const response = await api.post('./api/v1/Persons/PoliceCertificates', data);
      commit(muSetPolicecertificate, response.data);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },
  async saveCertificate({ dispatch, commit }, item) {
    commit(muSetPolicecertificateLoading);
    try {
      if (item.personId <= 0) {
        commit(muSetPolicecertificateError, { errorMessage: 'PersonId must have a value.' });
        return;
      }
      if (item.orgId <= 0) {
        commit(muSetPolicecertificateError, { errorMessage: 'OrgId must have a value.' });
        return;
      }
      let policeCertificateId = item.policeCertificateId;
      const data = {
        "policeCertificateId": Number(item.policeCertificateId),
        "orgId": Number(item.orgId),
        "personId": Number(item.personId),
        "comment": '',
        "fromDate": item.fromDate,
        "isValid": item.isValid,
        "attestPersonId": Number(item.attestPersonId),
        "statusId": Number(item.statusId)
      };
      if (item.policeCertificateId > 0) {
        await api.put('api/v1/PoliceCertificate', data);
      } else {
        const response = await api.post('./api/v1/PoliceCertificate', data);
        policeCertificateId = response.data.policeCertificateId;
      }
      await dispatch('fetchPoliceCertificateById', policeCertificateId);
      commit(muSavePolicecertificate);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  },

  async deleteCertifiate({ commit }, item) {
    commit(muSetPolicecertificateLoading);
    try {
      await api.delete(`./api/v1/PoliceCertificate?id=${item}`);
    commit(muSavePolicecertificate);
    } catch (e) {
      commit(muSetPolicecertificateError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muSetPolicecertificateLoading]() {
    state.loading = true;
    state.errorMessage = '';
  },

  [muSetPolicecertificate](_, items) {
    state.loading = false;
    state.policecertificate = items[0];
  },

  [muSetPolicecertificateById](_, item) {
    state.loading = false;
    state.policecertificate = item;
  },

  [muSetPolicecertificates](_, items) {
    state.loading = false;
    state.policecertificates = items;
  },

  [muSetPolicecertificateByStatus](_, items) {
    state.loading = false;
    state.policecertificatesbystatus = items;
  },

  [muSetPoliceStatistics](_, item) {
    state.loading = false;
    state.statistics = item;
  },

  [muSavePolicecertificate]() {
    state.loading = false;
    state.errorMessage = '';
  },

  [muSetPolicecertificateError](_, { errorMessage }) {
    state.loading = false;
    state.errorMessage = errorMessage;
  }
};

export const policecertificates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};