import {
  muSetOrgs,
  muSetOrgsLoading,
  muSetOrgsError
} from './mutation-types';

import api from '@/utils/api';

const state = {
  orgs: [],
  loading: false
};

const getters = {
  orgs: () => state.orgs,
  getOrgName: () => orgId => {
    const org = state.orgs.find(x => x.orgId === orgId);
    return org?.orgName;
  },
  loading: () => state.loading
};

const actions = {
  async fetchOrgs({ commit }, orgIds) {
    commit(muSetOrgsLoading);

    try {
      if (!orgIds.length) {
        commit(muSetOrgs, []);
        return;
      }

      const data = { orgIds };

      const response = await api.post('./api/v1/Org/Search', data);

      commit(muSetOrgs, response.data);
    } catch (e) {
      commit(muSetOrgsError, { errorMessage: e.message });
    }
  }
};

const mutations = {
  [muSetOrgsLoading](stateLoading) {
    stateLoading.loading = true;
    stateLoading.errorMessage = '';
  },

  [muSetOrgs](stateItems, items) {
    stateItems.loading = false;
    stateItems.orgs = items;
  },

  [muSetOrgsError](stateError, { errorMessage }) {
    stateError.loading = false;
    stateError.errorMessage = errorMessage;
  }
};

export const orgs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
