<template>
  <v-app>
    <page-header />
    <v-main>
      <router-view></router-view>
      <page-footer />
    </v-main>
  </v-app>
</template>

<script>
import PageHeader from '@/views/common/components/PageHeader.vue';
import PageFooter from '@/views/common/components/PageFooter.vue';

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter
  }
};
</script>