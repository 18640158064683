<template>
  <v-card dark :width="width" class="primary app-menu-card">
    <v-card-text class="pa-10">
      <div class="text-left text-animated pb-4" @click="$emit('click')">
        <router-link to="/" class="white--text">{{
          $t("applicationStrings.homePage")
        }}</router-link>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
      <div class="text-left text-animated pb-4">
        <a href="#" @click="goToCoachCertificate()" class="white--text">{{
          $t("applicationStrings.coaches")
        }}</a>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
       <div class="text-left text-animated pb-4">
        <a href="#" @click="goToUserGuidance()" class="white--text">{{
          $t("applicationStrings.guidance")
        }}</a>
        <v-icon dark>mdi-arrow-right</v-icon>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["width"],
  methods: {
    goToCoachCertificate() {
      const url = process.env.VUE_APP_COACH_URL + "/trener/aktive";
      window.open(url);
    },
    goToUserGuidance(){
      const url = "https://www.idrettsforbundet.no/digital/politiattest/brukerveiledninger/";
      window.open(url);
    }

  }
};
</script>

<style scoped>
@media (min-width: 600px) {
  .v-card__text {
    border: 2px solid white;
  }
}
</style>

