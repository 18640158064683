<template>
  <v-list nav class="primary--text">
    <v-list-item-group>
      <v-list-item
        v-for="lang in langs"
        :key="lang.key"
        @click="locale = lang.key"
      >
        <v-list-item-icon>
          <v-icon v-if="locale === lang.key">mdi-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ lang.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ lang.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'locale-changer',
  data: () => ({
    langs: [
      {
        key: 'no',
        title: 'Norsk (bokmål)',
        subtitle: 'Alt innhold er tilgjengelig på bokmål'
      },
      {
        key: 'en',
        title: 'English',
        subtitle: 'Some content is available in English'
      }
    ]
  }),
  computed: {
    locale: {
      set(locale) {
        this.$root.$i18n.locale = locale;
      },
      get() {
        return this.$root.$i18n.locale;
      }
    }
  }
};
</script>