import {
  muSetContextOrgId
} from './mutation-types';

const state = {
  contextOrgId: 0
};

const storeGetters = {
  contextOrgId: () => state.contextOrgId,
  oidcUser: (_, _getters, _rootState, rootGetters) => rootGetters['oidcStore/oidcUser'],
  orgTypeId: (_, getters) => getters.getOrgContext(state.contextOrgId)?.orgTypeId,
  orgContexts(_, getters) {
    const { oidcUser } = getters;
    const context = [];

    if (!oidcUser) {
      return context;
    }
    const orgIds = oidcUser.orgids.split(',').map(x => Number(x));
    const orgTypeIds = oidcUser.orgtypeids.split(',').map(x => Number(x));
    const userIds = oidcUser.userids.split(',').map(x => Number(x));
    const functionTypeIds =  oidcUser.functiontypeids.split(',').map(x => Number(x));
    const roleLevelIds = oidcUser.rolelevelids.split(',').map(x => Number(x));

    for (let i = 0; i < orgIds.length; i++){
      let orgContext = context.find(x => x.orgId === orgIds[i]);

      if (!orgContext){
        orgContext = {
          orgId: orgIds[i],
          orgTypeId: orgTypeIds[i],
          userContexts: []
        };

        context.push(orgContext);
      }

      orgContext.userContexts.push({
        userId: userIds[i],
        functionTypeId: functionTypeIds[i],
        roleLevelId: roleLevelIds[i]
      });
    }

    return context;
  },
  getOrgContext: (_, getters) => orgId => getters.orgContexts.find(x => x.orgId === orgId),
  buypassId: (_, getters) => getters.oidcUser?.buypassid,
  applicationId: (_, getters) => getters.oidcUser?.application_id,
  email: (_, getters) => getters.oidcUser?.email,
  givenName: (_, getters) => getters.oidcUser?.given_name,
  familyName: (_, getters) => getters.oidcUser?.family_name,
  fullName: (_, getters) => {
    if (getters.oidcUser) {
      return `${getters.oidcUser?.given_name} ${getters.oidcUser?.family_name}`;
    } else {
      return '';
    }
  },
  personId(_, getters) {
    const { personid } = getters.oidcUser;
    if(Array.isArray(personid)){
      return personid[0];
    }else{
      return personid;
    }
  },
  userId() {
    const userId = this.getOrgContext(state.contextOrgId)
      ?.userContexts.find(() => true)
      ?.userId;
    return userId;
  },
  functionTypeIds() {
    const functionTypeIds = this.getOrgContext(state.contextOrgId)
      ?.userContexts
      ?.map(x => x.functionTypeId);
    return functionTypeIds;
  },
  roleLevelIds() {
    const roleLevelIds = this.getOrgContext(state.contextOrgId)
    ?.userContexts
    ?.map(x => x.roleLevelId);
    return roleLevelIds;
  }
};

const actions = {
  setContextOrgId({ commit }, orgId) {
    commit(muSetContextOrgId, { orgId });
  }
};

const mutations = {
  [muSetContextOrgId](_, { orgId }) {
    state.contextOrgId = orgId;
  }
};

export const claims = {
  namespaced: true,
  state,
  getters: storeGetters,
  actions,
  mutations
};
