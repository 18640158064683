import store from '@/store';

export const authorizeMixin = {
    methods: {
        isOrgTypesAllowed: function (orgTypes) {
            let orgTypeAllowed = false;
            if (orgTypes.length > 0) {
                const contextOrgId = store.getters['claims/contextOrgId'];
                const getOrgContext = store.getters['claims/getOrgContext'];
                const orgTypeId = getOrgContext(contextOrgId)?.orgTypeId;
                orgTypeAllowed = orgTypes.includes(orgTypeId);
            }
            return orgTypeAllowed;
        }
    }
  };