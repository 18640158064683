import Vue from 'vue';
import Vuex from 'vuex';

import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '@/config/oidc';
import { claims } from './modules/claims';
import { personAvatar } from './modules/personAvatar';
import { persons } from './modules/persons';
import { memberships } from './modules/memberships';
import { sports } from './modules/sports';
import { orgs } from './modules/orgs';
import { coaches } from './modules/coaches';
import { policecertificates } from './modules/policecertificates';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const oidcStore = vuexOidcCreateStoreModule(oidcSettings, { namespaced: true });

export default new Vuex.Store({
  modules: {
    oidcStore,
    claims,
    sports,
    orgs,
    personAvatar,
    persons,
    memberships,
    coaches,
    policecertificates
  },
  strict: debug
});