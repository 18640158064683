export const Role = {
    Admin: [
        //Leder
        1,
        //Nestleder
        2,
        //Daglig leder
        15,
        //Adm.leder (ansatt)
        24,
        //Medlemsansvarlig
        40000004,
        //Org.ansvarlig KL
        40000008,
        //Superadministrator Sportsadmin
        50000015
    ],
    PoliceCertificateAdmin: [
        //Ansvarlig politiattest
        202401,
        //Ansvarlig politiattest vara
        202727
    ],
    SuperAdmin: [
        //Superadministrator Sportsadmin
        50000015
    ]
};

export default Role;