<template>
  <v-card class="mx-auto mt-2" elevation="0" width="350" max-height="650" @click="$emit('click')">
    <div class="primary--text white">
      <Localization />
      <v-list nav dense subheader>
        <v-subheader>{{ $t('commonStrings.changeOrg') }}</v-subheader>
        <v-list-item-group v-model="index" color="primary">
          <v-list-item
            v-for="orgContext in filteredOrgContexts"
            :key="orgContext.orgId"
          >
            <v-list-item-icon>
              <v-icon v-if="orgContext.orgId == contextOrgId">mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="orgContext">
                {{ getOrgName(orgContext.orgId) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="text-center pb-5 mb-10">
        <v-btn depressed rounded outlined large width="230" @click="logOut"
          class="v-btn--accent-border text-none">
          {{ $t('commonStrings.signOut') }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Localization from '@/views/common/components/Localization.vue';
import { Role } from '@/plugins/role';

export default {
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapGetters('oidcStore', [
      'oidcIsAuthenticated'
    ]),
    ...mapGetters('claims', [
      'personId',
      'contextOrgId',
      'orgContexts'
    ]),
    ...mapGetters('orgs', [
      'orgs'
    ]),
    filteredOrgContexts() {
      const allowedFuntionTypes = Role.PoliceCertificateAdmin;
      return this.orgContexts
        .filter(orgContext => orgContext
          .userContexts
          .some(userContext => allowedFuntionTypes
            .indexOf(userContext.functionTypeId) > -1)
        );
    },
    contextOrgIdKey() {
      return `contextOrgId_${this.personId}`;
    },
    index: {
      set(index) {
        if (typeof index !== 'undefined') {
          const { orgId } = this.filteredOrgContexts[index];
          this.setContextOrgId(orgId);
        }
      },
      get() {
        if (this.contextOrgId) {
          return this.filteredOrgContexts.findIndex(x => x.orgId === this.contextOrgId);
        } else {
          return 0;
        }
      }
    }
  },
  methods: {
    ...mapActions('oidcStore', [
      'signOutOidc'
    ]),
    ...mapActions('claims', [
      'setContextOrgId'
    ]),
    logOut(){
      this.signOutOidc();
    },
    getOrgName(orgId) {
      const org = this.orgs.find(x => x.orgId === orgId);
      return org?.describingName;
    }
  },
  components: {
    Localization
  }
};
</script>
